import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useState } from 'react';

import Linkify from '../../../util/Linkify';
import FileBox from './FileBox';
import ImageBox from './ImageBox';
import { messageComponentType } from './messageType';
// import ReadIcon from './ReadIcon';
import { Time } from './Time';
import VideoBox from './VideoBox';

const LeftMessageBox = ({
  mode,
  msgCard,
  preMsgCard,
  nextMsgCard,
}: messageComponentType) => {
  console.log(msgCard);
  const [isExpand, setExpand] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const onMessageClick = (event) => {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    window?.message.onClick(JSON.stringify(msgCard));
  };
  const onContentsClick = (event) => {
    event.preventDefault();
    const index = event.currentTarget.dataset.index;
    const currentTime = dayjs().valueOf();
    if (currentTime - lastClickTime > 1500) {
      setLastClickTime(currentTime);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      window?.action.onClick(parseInt(index), JSON.stringify(msgCard));
    }
  };
  const onActionClick = (event) => {
    event.preventDefault();
    const index = event.currentTarget.dataset.index;
    const currentTime = dayjs().valueOf();
    if (currentTime - lastClickTime > 2500) {
      setLastClickTime(currentTime);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      window?.action.onClick(parseInt(index), JSON.stringify(msgCard));
    }
  };

  const onExpandClick = (isExpand, msgCard) => {
    const currentTime = Date.now();
    if (currentTime - lastClickTime > 1500) {
      setLastClickTime(currentTime);
      if (isExpand || msgCard.actions.length < 4) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        window?.message.onNoAnswerClick(JSON.stringify(msgCard));
      }
      setExpand(!isExpand);
    }
  };

  const isDisableByReactBot = () => {
    if (msgCard.actions) {
      return msgCard.actions.some((action) => {
        return action.data && action.data.read;
      });
    }
    return false;
  };
  return (
    <div
      data-drag-type='chat'
      className='mt-2.5 mb-2.5 text-left removeHighlight'
    >
      <div data-drag-type='chat' className={'pb-1 ml-4'}>
        <div
          data-drag-type='chat'
          className={'flex flex-col pt-1 items-start '}
        >
          {preMsgCard ? (
            preMsgCard.writerType !== msgCard.writerType && (
              <div className='flex items-center mb-1'>
                <div
                  className={
                    'w-[2rem] h-[2rem] rounded-full bg-secondary-light'
                  }
                >
                  <img
                    className={
                      msgCard.writerType.includes('bot') ? 'p-0' : 'p-1.5'
                    }
                    src={
                      mode === 1
                        ? msgCard.writerType.includes('bot')
                          ? './vetty_small.png'
                          : './hospital_profile.webp'
                        : './nok_profile.webp'
                    }
                    alt='profile_icon'
                  />
                </div>
                <span className='ml-2 text-sm text-[#808495]'>
                  {msgCard.writerType.includes('bot')
                    ? '챗봇 베티'
                    : msgCard.writer}
                </span>
              </div>
            )
          ) : (
            <div className='flex items-center mb-1'>
              <div
                className={'w-[2rem] h-[2rem] rounded-full bg-secondary-light'}
              >
                <img
                  src={
                    mode === 1
                      ? msgCard.writerType.includes('bot')
                        ? './vetty_small.png'
                        : './hospital_profile.webp'
                      : './nok_profile.webp'
                  }
                  alt='profile_icon'
                />
              </div>
              <span className='ml-2 text-sm text-[#808495]'>
                {msgCard.writerType.includes('bot')
                  ? '챗봇 베티'
                  : msgCard.writer}
              </span>
            </div>
          )}
          <div
            className={
              msgCard.images ||
              msgCard.videos ||
              msgCard.actions ||
              msgCard.files
                ? 'w-full max-w-3/4 ml-[2rem]'
                : 'ml-[2rem] max-w-3/4'
            }
          >
            <div
              className={
                'rounded-b-2xl rounded-r-2xl text-base ' +
                (msgCard.writerType.includes('bot')
                  ? 'bg-secondary-light text-neutral-dark '
                  : 'bg-primary text-white')
              }
            >
              <ul
                data-drag-type='chat'
                className={
                  'break-all whitespace-pre-wrap ' +
                  (!msgCard.images && !msgCard.videos
                    ? 'p-3 overflow-hidden'
                    : 'rounded-b-2xl rounded-r-2xl overflow-hidden')
                }
              >
                {msgCard.images &&
                  msgCard.images.map((msgObject, index) => {
                    return (
                      <ImageBox
                        key={msgObject.link}
                        isLeft={true}
                        imageUrl={msgObject.link}
                        onClick={onMessageClick}
                      />
                    );
                  })}
                {msgCard.videos &&
                  msgCard.videos.map((msgObject, index) => {
                    return (
                      <VideoBox
                        key={msgObject.link}
                        video={msgObject}
                        onClick={onMessageClick}
                      />
                    );
                  })}
                {msgCard.files &&
                  msgCard.files.map((msgObject, index) => {
                    return (
                      <FileBox
                        key={msgObject.link}
                        isLeft={true}
                        file={msgObject}
                        onClick={onMessageClick}
                      />
                    );
                  })}
                {msgCard.videos || msgCard.images ? (
                  ''
                ) : (
                  <Linkify
                    text={msgCard.text}
                    msgCard={msgCard}
                    position={msgCard.writerType.includes('vetflux') && 'left'}
                  />
                )}
                {msgCard.actions && (
                  <ul>
                    {msgCard.actions[0].type === 'list'
                      ? msgCard.actions.map((action, index) => {
                          if (
                            !isExpand &&
                            msgCard.writerType !== 'vetflux' &&
                            index > 2
                          ) {
                            return;
                          }
                          return (
                            <li
                              key={`0000${index}${action.link}`}
                              className={
                                'shadow-md bg-white mt-4 rounded-lg px-4 py-3 '
                              }
                            >
                              <div
                                className={'flex flex-row items-center gap-2'}
                              >
                                <button
                                  className={
                                    'w-full text-neutral-dark text-left'
                                  }
                                  data-index={index.toString()}
                                  onClick={onContentsClick}
                                >
                                  {/* <a href={msgObject.link}>{msgObject.name}</a> */}
                                  <a>{action.name}</a>
                                </button>
                                {/*{action.data && action.data.read && (*/}
                                {/*  <div>*/}
                                {/*    <ReadIcon />*/}
                                {/*  </div>*/}
                                {/*)}*/}
                              </div>
                            </li>
                          );
                        })
                      : msgCard.actions.map((action, index) => {
                          return (
                            <li
                              key={`0000${index}${action.link}`}
                              className={
                                'shadow-md bg-white mt-4 rounded-lg text-base px-4 py-3 text-primary'
                              }
                            >
                              <div className={'flex flex-row items-center'}>
                                <button
                                  className={
                                    'w-full disabled:text-neutral-normal'
                                  }
                                  data-index={index.toString()}
                                  disabled={isDisableByReactBot()}
                                  onClick={onActionClick}
                                >
                                  <a>{action.name}</a>
                                </button>
                                {/*{action.data && action.data.read && (*/}
                                {/*  <div>*/}
                                {/*    <ReadIcon />*/}
                                {/*  </div>*/}
                                {/*)}*/}
                              </div>
                            </li>
                          );
                        })}
                  </ul>
                )}
              </ul>
              {msgCard.writerType !== 'vetflux' &&
                msgCard.actions &&
                msgCard.actions[0].type === 'list' && (
                  <div className={'border-t border-white mt-2'}>
                    <button
                      className={
                        'flex flex-row items-center justify-center w-full text-primary text-base py-3'
                      }
                      onClick={() => {
                        onExpandClick(isExpand, msgCard);
                      }}
                    >
                      <span>
                        {isExpand
                          ? '원하는 답변이 없어요!'
                          : msgCard.actions.length > 3
                          ? '더보기'
                          : '원하는 답변이 없어요'}
                      </span>
                      {!isExpand && (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='22'
                          height='22'
                          viewBox='0 0 41 41'
                          className={'fill-[#6C5CE7] rotate-0'}
                        >
                          <defs>
                            <clipPath id='bc9ss4mvoa'>
                              <path d='m11 8.781 8.653-8.39a1.406 1.406 0 0 1 1.945 0 1.3 1.3 0 0 1 0 1.886l-9.625 9.333a1.406 1.406 0 0 1-1.945 0L.4 2.276A1.3 1.3 0 0 1 .4.391a1.406 1.406 0 0 1 1.945 0z' />
                            </clipPath>
                          </defs>
                          <g data-name='Icon/Chevron Bottom'>
                            <path
                              data-name='Mask'
                              d='m11 8.781 8.653-8.39a1.406 1.406 0 0 1 1.945 0 1.3 1.3 0 0 1 0 1.886l-9.625 9.333a1.406 1.406 0 0 1-1.945 0L.4 2.276A1.3 1.3 0 0 1 .4.391a1.406 1.406 0 0 1 1.945 0z'
                              transform='translate(9 14)'
                            />
                          </g>
                        </svg>
                      )}
                    </button>
                  </div>
                )}
            </div>
          </div>
          {msgCard.writerType !== 'bot_pet_data' && (
            <Time
              isLeft={true}
              preMsgCard={preMsgCard}
              msgCard={msgCard}
              nextMsgCard={nextMsgCard}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const areEqual = (
  prevProps: messageComponentType,
  nextProps: messageComponentType,
) => {
  return _.isEqual(prevProps, nextProps);
};
export const MemoizedLeftMessageBox = React.memo(LeftMessageBox, areEqual);
