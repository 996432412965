import LinkifyText from 'src/features/common/LinkifyText';

export const DescriptionQna = ({
  description,
  imageUrl,
}: {
  description: string;
  imageUrl?: string;
}) => {
  return (
    <>
      <div className={'flex flex-col'}>
        <LinkifyText text={description} />
        {imageUrl && (
          <img
            alt={'description_img'}
            src={imageUrl}
            className={'w-full mt-3'}
            style={{ objectFit: 'cover' }}
          />
        )}
      </div>
    </>
  );
};
