'use client';

import React, { useEffect, useState } from 'react';

/* styles */

const URL_REGEX = /(https?:\/\/[^\s]+)/g;

function LinkifyText({ text }: { text: string }) {
  const [parts, setParts] = useState<string[]>([]);

  useEffect(() => {
    setParts(text.split(URL_REGEX));
  }, [text]);

  return (
    <>
      {parts.map((part, index) => {
        if (part.match(URL_REGEX)) {
          return (
            <a
              href={part}
              key={`${part}-${index}`}
              target={'_blank'}
              className={'mx-5 text-base text-[#4430E0] whitespace-pre-line'}
              rel='noreferrer'
            >
              {part}
            </a>
          );
        } else {
          return (
            <p
              key={`${part}-${index}`}
              className={'mx-5 text-base text-neutral-dark whitespace-pre-line'}
            >
              {part}
            </p>
          );
        }
      })}
    </>
  );
}

export default LinkifyText;
